<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0" style="float: left">
                    {{ $t("manage_user") }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>

                  <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="800px"
                    class="float-right"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="float-right"
                        style="background-color: #d6d0fc"
                      >
                        <span style="font-size: 1em; color: #3316f2">
                          <i class="fas fa-user-plus">
                            <label class="ml-2 btn_create_new">{{
                              $t("create_user")
                            }}</label>
                          </i>
                        </span>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title v-if="this.userId != ''">{{
                        $t("edit_user")
                      }}</v-card-title>
                      <v-card-title v-else>{{
                        $t("create_user")
                      }}</v-card-title>

                      <v-icon
                        class="btn_close"
                        @click="
                          dialog = false;
                          clear();
                          resetValidation();
                        "
                        >close</v-icon
                      >
                      <v-divider />
                      <v-card-text
                        style="
                          height: auto;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-form ref="form" v-model="valid">
                            <v-row class="mt-2">
                              <v-col cols="6">
                                <label class="label required">{{
                                  $t("name_en")
                                }}</label>
                                <v-text-field
                                  class="my-3"
                                  v-model="name_en"
                                  placeholder="e.g: Jonh Smith"
                                  solo-inverted
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <label class="label required">{{
                                  $t("name_kh")
                                }}</label>
                                <v-text-field
                                  class="my-3"
                                  v-model="name_kh"
                                  placeholder="e.g: ចន ស្មុីត"
                                  solo-inverted
                                  :rules="[rules.required]"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="6">
                                <label class="label required">{{
                                  $t("email")
                                }}</label>
                                <v-text-field
                                  class="my-3"
                                  v-model="email"
                                  append-icon="fa-id-card"
                                  placeholder="e.g: jonhsmith@gmial.com"
                                  solo-inverted
                                  :rules="[rules.email]"
                                  :readonly="this.userId != ''"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                <label
                                  class="label required"
                                  v-show="isDisabled"
                                  >{{ $t("role") }}</label
                                >

                                <v-select
                                  class="my-3"
                                  v-model="access_roles"
                                  solo-inverted
                                  multiple
                                  chips
                                  item-text="name_en"
                                  item-value="value"
                                  :items="listRoles"
                                  label="All"
                                  v-show="isDisabled"
                                  :rules="[rules.required]"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog = false), clear(), resetValidation()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn_save_close"
                          :disabled="!valid"
                          @click.prevent="onSaveClose(), validate()"
                          >{{ $t("save_close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider>
                <v-col sm="12" cols="12" class="py-0">
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-col sm="4" cols="12">
                          <v-text-field
                            outlined
                            append-icon="search"
                            label="Search"
                            v-model="search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-card-title>
                      <v-data-table
                        item-key="id"
                        class="elevation-1"
                        :headers="headers"
                        :items="listUser"
                        :search="search"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [10, 25, 50, -1],
                        }"
                        hide-default-header
                      >
                        <template v-slot:header="{}">
                          <thead>
                            <tr>
                              <th>
                                {{ $t("no") }}
                              </th>
                              <th>
                                {{ $t("photo_url") }}
                              </th>
                              <!-- <th>
                                {{ $t("username") }}
                              </th> -->
                              <th>
                                {{ $t("name_en") }}
                              </th>
                              <th>
                                {{ $t("name_kh") }}
                              </th>
                              <th>
                                {{ $t("email") }}
                              </th>
                              <!-- <th>
                                {{ $t("display_name") }}
                              </th>
                              <th>
                                {{ $t("uid") }}
                              </th> -->
                              <th>
                                {{ $t("role") }}
                              </th>
                              <th>
                                {{ $t("action") }}
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody style="white-space: nowrap">
                            <tr
                              v-for="(user, index) in items"
                              v-bind:key="index"
                            >
                              <td>{{ 1 + index++ }}</td>
                              <td width="40px">
                                <v-row align="center" class="mt-2 mb-2">
                                  <v-img
                                    v-if="user.photoURL"
                                    class="rounded-circle img_border"
                                    :src="user.photoURL"
                                    height="auto"
                                    width="40px"
                                  ></v-img>
                                  <v-img
                                    v-if="!user.photoURL"
                                    class="rounded-circle img_border"
                                    src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Fuser.png?alt=media&token=7260a3c7-948d-4b98-826e-107d748944df"
                                    alt="User"
                                    width="40px"
                                  ></v-img>
                                </v-row>
                              </td>
                              <!-- <td>{{ user.username }}</td> -->
                              <td>{{ user.name_en }}</td>
                              <td>{{ user.name_kh }}</td>
                              <td>{{ user.email }}</td>
                              <!-- <td>{{ user.displayName }}</td>
                              <td>{{ user.uid }}</td> -->

                              <!-- <td>
                                <v-chip
                                  :color="getColor(user.access_roles[0])"
                                  dark
                                >
                                  {{ user.access_roles[0] }}
                                </v-chip>
                              </td> -->
                              <td style="max-width: 25vh; overflow-x: auto">
                                <span
                                  v-for="(role, index) in user.access_roles"
                                  :key="index"
                                >
                                  <v-chip
                                    class="ma-1"
                                    :color="getColor(role)"
                                    dark
                                  >
                                    {{ role }}
                                  </v-chip>
                                </span>
                              </td>

                              <td class="text-center">
                                <v-btn
                                  @click="onEditItem(user)"
                                  fab
                                  small
                                  class="mx-2"
                                  color="primary"
                                >
                                  <v-icon size="15" dark>fa fa-pen</v-icon>
                                </v-btn>
                                <v-btn
                                  @click="deleteitem(user)"
                                  class="mx-2"
                                  fab
                                  color="error"
                                  small
                                >
                                  <v-icon size="15" dark>fa fa-trash</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import store from "@/store";
import {
  getUser,
  // getAccessRole,
  updateUser,
  createUser,
  listRole,
  deleteUser,
} from "@schoolbase/web-client-lib";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      isLoading: false,
      myLoading: false,
      search: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      headers: [
        { text: "No", value: "no" },
        { text: "PhotoURL", value: "photoURL" },
        { text: "Username", value: "username" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "Email", value: "email" },
        { text: "Display Name", value: "displayName" },
        { text: "Role", value: "access_roles" },
        { text: "UserId", value: "uid" },
      ],
      rules: {
        required: (v) => !!v || "This field is required",
        email: (v) => !!(v || "").match(/@/) || "Please enter a valid email",
      },
      listUser: [],
      listRoles: listRole,
      dialog: false,
      form: false,
      valid: true,
      photoURL: "",
      username: "",
      token: store.getters.getToken,
      name_en: "",
      name_kh: "",
      email: "",
      userId: "",
      uid: "",
      displayName: "",
      access_roles: "",
    };
  },
  computed: {
    isDisabled() {
      if (
        this.access_roles[0] == "lecturer" ||
        this.access_roles[0] == "student"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getColor(access_roles) {
      if (access_roles === "super_admin") {
        return "green accent-4";
      } else if (access_roles === "lecturer") {
        return "deep-purple lighten-2";
      } else if (access_roles === "student") {
        return "teal lighten-2";
      } else if (access_roles === "top_manager") {
        return "indigo accent-1";
      } else if (access_roles === "major_admin_cs") {
        return "green darken-1";
      } else if (access_roles === "major_admin_ecom") {
        return "pink";
      } else if (access_roles === "major_admin_tn") {
        return "lime";
      } else if (access_roles === "major_admin_foundation") {
        return "cyan";
      } else {
        return "blue lighten-2";
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.validate();
    },
    onEditItem(item) {
      this.username = item.username;
      this.access_roles = item.access_roles;
      this.email = item.email;
      this.name_en = item.name_en;
      this.name_kh = item.name_kh;
      this.displayName = item.displayName;
      this.photoURL = item.photoURL;
      this.userId = item.id;
      this.uid = item.uid;
      this.dialog = true;
    },
    //load User Function
    async loadUser() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const response = await getUser(this.token);
        this.listUser = response.payload.sort(function (a, b) {
          var nameA = a.name_en;
          var nameB = b.name_en;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        if (response.token) {
          this.$store.commit("LOGGED_TOKEN", response.token);
        }
        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
    //Create User Function
    async onSaveClose() {
      const data = {
        username: this.email,
        access_roles: this.access_roles,
        email: this.email,
        name_kh: this.name_kh,
        name_en: this.name_en,
        uid: this.uid,
        displayName: this.name_en,
        photoURL: this.photoURL,
      };
      if (this.userId !== "") {
        try {
          await updateUser(this.userId, data);
          this.loadUser();
          this.snackbar = {
            message: "Successfully Update User",
            color: "success",
            show: true,
          };
        } catch (e) {
          window.console.log(e.message);
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      } else {
        try {
          await createUser(data);
          this.loadUser();
          this.snackbar = {
            message: "Successfully Create User",
            color: "success",
            show: true,
          };
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      }
      this.resetValidation();
      this.clear();
      this.dialog = false;
    },
    // async loadRole() {
    //   const response = await getAccessRole();
    //   this.listRoles = response.payload;
    // },
    clear() {
      (this.username = ""),
        (this.access_roles = ""),
        (this.email = ""),
        (this.name_kh = ""),
        (this.name_en = ""),
        (this.uid = ""),
        (this.userId = ""),
        (this.displayName = ""),
        (this.photoURL = "");
    },
    async deleteitem(user) {
      const index = this.listUser.indexOf(user);
      this.deletItems = user;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        this.listUser.splice(index, 1);
        await this.deleteItem();
      }
    },
    async deleteItem() {
      try {
        await deleteUser(this.deletItems.id);
        this.snackbar = {
          message: "Delete User Successfully",
          color: "success",
          show: true,
        };
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },

  async mounted() {
    this.loadUser();
  },
  components: {
    Loading,
  },
};
</script>
<style scoped>
.img_border {
  width: auto;
  height: auto;
  border: double 3px transparent;
  border-radius: 80px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #743ad5, #d53a9d);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}
.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}
.btn_create_new {
  color: #3316f2 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}

.v-menu__content {
  top: 141px !important;
  left: 1098px !important;
}

.v-menu__content .v-list .v-list-item {
  min-height: 35px !important;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

.dropdown_transactions.v-btn {
  background-color: #2ca01c !important;
  height: 40px !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0px 10px 0px 10px !important;
  color: #fff !important;
  right: 12px;
  position: absolute;
  top: 7px;
}

@media (max-width: 576px) {
}
</style>
